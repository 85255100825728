import {
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import Login from './pages/login/login';
import Home from './pages/home/home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/accueil" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
