import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { toggleStatus } from '../../store/reducers/simulationResultReducer';

// Color could be error, warning, info, success
const SimulationResultComponent = ({ simulationData, screenSize }) => {

    /*
    const handleCloseSnackBar = (event, reason) => {

    };
    */

    let dispatch = useDispatch();

    console.log(screenSize);

    const [isActiveResults, setActiveResults] = useState(false);

    const handleToggleResults = () => {
        setActiveResults(!isActiveResults);
        dispatch(toggleStatus())
    };

    const resultData = useSelector((state) => state.simulationResult.value);

    return (
        <div className={"home-inner_body_results " + (isActiveResults ? "close" : "open")}>
            <div className="home-inner_body_results_content">
                <div className="home-inner_body_results_content_item">
                    <h3>Résultats</h3>
                    <p>{simulationData.lampDistribute} Lampadaires à distribuer</p>
                </div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Total</h3>
                        <Tooltip title="info" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalLampadaires}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Frontières total</h3>
                        <Tooltip title="info" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalFrontiere}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Savanes</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontSavanes} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Ouest</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontOuest} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereOuest}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Est</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontEst} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereEst}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Sud_est</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontSudEst} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereSudEst}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Villages CIZO total</h3>
                        <Tooltip title="info" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalVillageCiso}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Regions des savanes</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.savanesCizo12} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalVillageCisoSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Autres régions</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.autresRegionsCizo12} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalVillageCisoAutresRegions}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Zone CIZO total</h3>
                        <Tooltip title="info" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalZoneCiso}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Regions des savanes</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.savanesCizoEP} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalZoneCisoSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Autres régions</span>
                            <Tooltip title="info" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.autresRegionsCizoEP} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalZoneCisoAutresRegions}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <h3>Infrastructures non-éclairés hors CIZO total</h3>
                    <Tooltip title="info" placement="top" arrow>
                        <InfoOutlinedIcon className="info" />
                    </Tooltip>
                </div>
                <div className="home-inner_body_results_content_item no-light">
                    <ul>
                        <li>
                            <span>Education</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalInfraHorsCisoEducation}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item no-light">
                    <ul>
                        <li>
                            <span>Santé</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalInfraHorsCisoSante}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item no-light">
                    <ul>
                        <li>
                            <span>Points d'eau</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalInfraHorsCisoEau}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
            </div>
            <div className="home-inner_body_results_footer">
                <Button>
                    <FileDownload />
                    télécharger
                </Button>
            </div>
            <div className="home-inner_body_results_collapse">
                <Tooltip title={isActiveResults ? "Afficher" : "Cacher"} placement="top" arrow>
                    <button onClick={handleToggleResults}><ArrowForwardIosIcon /></button>
                </Tooltip>
            </div>
        </div>
    )
}

export default SimulationResultComponent;