import React from "react";
import L from "leaflet";

// Possible improvements:
// - prevent all base layers to be loaded each time the zoom level changes
// - add a zoom reset button

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      layersOnMap: {},
    };
  }

  // why not put that in the constructor?
  // is it necessay to be async?
  async createMapElement() {
    const { latLng, zoom } = this.props;

    const mapOptions = {
      minZoom: 6,
      maxZoom: 18,
      maxBounds: L.latLngBounds(L.latLng(4, -2), L.latLng(12.5, 5)),
    };

    await this.setState({
      map: L.map("map", mapOptions).setView(latLng, zoom),
    });
  }

  addScaleToMap() {
    L.control.scale().addTo(this.state.map);
  }

  addBaseLayersToMap() {
    const addLayerToMap = (infoLayer) => {
      var layer = L.tileLayer(infoLayer.urlTemplate, {
        attribution: infoLayer.options.attribution,
        maxZoom: infoLayer.options.maxZoom,
      });
      this.state.map.addLayer(layer);
      return layer;
    };

    var [
      infoBaselayer1,
      infoBaselayer2,
      infoBaselayer3,
    ] = this.props.baseLayers;

    var layer1 = addLayerToMap(infoBaselayer1);
    var layer2 = addLayerToMap(infoBaselayer2);
    var layer3 = addLayerToMap(infoBaselayer3);

    var baseMaps = {};
    baseMaps[infoBaselayer3.name] = layer3;
    baseMaps[infoBaselayer2.name] = layer2;
    baseMaps[infoBaselayer1.name] = layer1;

    var overlayMaps = {
      /*Those are added manually*/
    };

    L.control.layers(baseMaps, overlayMaps).addTo(this.state.map);
  }

  addLatLngInfoOnMap = (e) => {
    if (e.originalEvent.shiftKey)
      L.popup()
        .setLatLng(e.latlng)
        .setContent(
          e.latlng.toString() +
          "<br/>" +
          "zoom level: " +
          +this.state.map.getZoom()
        )
        .openOn(this.state.map);
  };

  checkLayerCurrentlyDisplayedOnMap(state, layerSlug) {
    if (layerSlug in state.layersOnMap && state.layersOnMap[layerSlug] !== null)
      return true;
    return false;
  }

  removePreviousOverlayLayers(newState) {
    const { layers, displayedLayers } = this.props;

    layers.map((layer) => {
      const shouldBeDisplayed = displayedLayers[layer.slug];
      const shouldBeForcedlyUpdated = layer.forceUpdate;

      if (
        (!shouldBeDisplayed || shouldBeForcedlyUpdated) &&
        this.checkLayerCurrentlyDisplayedOnMap(newState, layer.slug)
      ) {
        newState.layersOnMap[layer.slug].remove();
        newState.layersOnMap = {
          ...newState.layersOnMap,
          [layer.slug]: null,
        };
      }
      return null;
    });
  }

  addOverlayLayersFromPropsData(newState) {
    const {
      layers,
      displayedLayers,
      mapData,
      layerSelectionButtons,
    } = this.props;

    layers.map((layerInfo) => {
      //console.log(layerInfo);
      const shouldBeDisplayed = displayedLayers[layerInfo.slug];

      if (
        shouldBeDisplayed &&
        !this.checkLayerCurrentlyDisplayedOnMap(newState, layerInfo.slug)
      ) {

        const newLayer = layerInfo.addToMap(
          newState.map,
          mapData,
          layerSelectionButtons
        );

        newState.layersOnMap = {
          ...newState.layersOnMap,
          [layerInfo.slug]: newLayer,
        };

        // should be based instead on a flag inside the layer info
        if (layerInfo.slug === "quartier") newLayer.bringToBack();
      }
      return null;
    });
    console.log(mapData);
  }

  async componentDidMount() {
    await this.createMapElement();
    this.addScaleToMap();
    this.addBaseLayersToMap();

    this.state.map.on("click", this.addLatLngInfoOnMap);

    var newState = { ...this.state };
    this.addOverlayLayersFromPropsData(newState);
    this.setState(newState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.zoom !== this.props.zoom ||
      prevProps.latLng !== this.props.latLng
    ) {
      // we don't need a setState as it is react that controlls the DOM here, right?
      this.state.map.setView(this.props.latLng, this.props.zoom);
    }

    // is it the right condition?
    // the state changes each time as we do a setState (does it? do we compare by reference in such a case?), whereas the props remain the same.
    // 1/12: not sure of the logic of this condition, or even why to even have this condition
    // 1/12: it seems it is here to avoid an infinite loop
    // 1/12: not sure the condition is right (prevProps !== this.props), as we update the state in the REMOVING function
    // and thus we could keep the same props but have a new state, for which we may want to do an action
    // 1/12: perhaps we could replace the condition by (prevProps !== this.props || prevState !== this.state)
    // to take in consideration change in both the props and the state
    if (prevProps !== this.props) {
      /* new version, we do only one setState, at the end, and thus we don't have issue
      with the asynchronous update due to the setState in the middle of componentDidUpdate */

      var newState = { ...this.state };
      this.removePreviousOverlayLayers(newState);
      this.addOverlayLayersFromPropsData(newState);
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this.state.map.off("click", this.addLatLngInfoOnMap);
  }

  render() {
    return (
      <div id="map" />
    );
  }
}

export default Map;
