import L from "leaflet";

import  SimulatedData  from '../../data/togo_eclairage_base_calcul.json';
//import  SimulatedDataOnCantons  from '../../data/togo_eclairage_calculated_cantons.json';
//import { useSelector } from 'react-redux';

/*
function ResultData(){
  const resultData = useSelector((state) => state.layersSimulated.value);
  return resultData;
}
*/
export const dataLoadInfos = [
  /*{
    dataUrl: "/local_db/simulation_calcul/togo_export_site_15000.geojson",
    fieldName: "frontiereKM0Info",
  },*/
  
  {
    dataUrl: "/local_db/togo_eclairage_base_calcul.geoJSON",
    fieldName: "eclairageCalculInfo",
  },
  {
    dataUrl: "/local_db/togo_eclairage_existant.geojson",
    fieldName: "frontiereKM0Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_1000.geojson",
    fieldName: "frontiereKM1Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_2000.geojson",
    fieldName: "frontiereKM2Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_3000.geojson",
    fieldName: "frontiereKM3Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_4000.geojson",
    fieldName: "frontiereKM4Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_5000.geojson",
    fieldName: "frontiereKM5Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_6000.geojson",
    fieldName: "frontiereKM6Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_7000.geojson",
    fieldName: "frontiereKM7Info",
  },{
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_8000.geojson",
    fieldName: "frontiereKM8Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_9000.geojson",
    fieldName: "frontiereKM9Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_10000.geojson",
    fieldName: "frontiereKM10Info",
  },
  /*{
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_11000.geojson",
    fieldName: "frontiereKM11Info",
  },*/
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_12000.geojson",
    fieldName: "frontiereKM12Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_13000.geojson",
    fieldName: "frontiereKM13Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_14000.geojson",
    fieldName: "frontiereKM14Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_15000.geojson",
    fieldName: "frontiereKM15Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_16000.geojson",
    fieldName: "frontiereKM16Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_17000.geojson",
    fieldName: "frontiereKM17Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_18000.geojson",
    fieldName: "frontiereKM18Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_19000.geojson",
    fieldName: "frontiereKM19Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_20000.geojson",
    fieldName: "frontiereKM20Info",
  }
 
];



let defaultStyle = {
    fillColor: "#FFF",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};

let firstStyle = {
    fillColor: "#FFF3B0",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};

let secondStyle = {
    fillColor: "#E09F3E",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};

let thirdStyle = {
    fillColor: "#9E2A2B",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};

let fourthStyle = {
    fillColor: "#540B0E",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};

/*
let fithStyle = {
    fillColor: "red",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 0.7,
    opacity: 1,
};
*/
export const FrontiereLayers = {
  slug: "existant",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const { kmDiametre } = layerSelectionButtons;
    const data = dataSource[kmDiametre];
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
              "Type de zone:  " +
              feature.properties["type"] +
              "<br>" +
              "Canton:  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_nom"] +
              "<br>" +
              "Population :  " +
              feature.properties["popCouv"] +
              "<br>" +
              "Lampadaires Existants :  " +
              feature.properties["lampCouv"] +
              "<br>"+
              "Ecoles à électrifier :  " +
              feature.properties["ecoleCouv"] +
              "<br>"+
              "Etablissements santé à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Points d'eau à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Ratio actuel :  " +
              feature.properties["hab_lamp"]  +
              "<br>"
          )
          .addTo(map);
          
      },
      style: function (feature) {
        //if ( firstLayer === "existant" ) {
          let nbr_habit = feature.properties["hab_lamp"];
          if (nbr_habit>0 && nbr_habit< 20) {
            return defaultStyle;
          }
          if (nbr_habit>=20 && nbr_habit< 60) {
            return firstStyle;
          }
          if (nbr_habit>=60 && nbr_habit<100) {
            return secondStyle;
          }
          if (nbr_habit>=100 && nbr_habit<200) {
            return thirdStyle;
          }
          if (nbr_habit>=200 || nbr_habit ===0) {
            return fourthStyle;
          }
        //}

      }
    });
    myLayer.addTo(map);
    return myLayer;
  },
};




export const FrontiereSimulatedLayers = {
  slug: "calculated",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change

    //const { simulatedInfo } = layerSelectionButtons;

    const data = SimulatedData.features;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Canton:  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_nom"] +
              "<br>" +
              "Population :  " +
              feature.properties["popCouv"] +
              "<br>" +
              "Lampadaires Existants :  " +
              feature.properties["lampCouv"] +
              "<br>"+
              "Ecoles à électrifier :  " +
              feature.properties["ecoleCouv"] +
              "<br>"+
              "Etablissements santé à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Points d'eau à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Ratio actuel :  " +
              feature.properties["hab_lamp"]  +
              "<br>"+
              "-------------------------------  " +
              "<br>"+
              "Ratio désiré :  20" +
            
              "<br>"+
              "Nouveaux Lampadaires à construire :  " +
              feature.properties["lamp_nov"]  +
              "<br>"
          )
          .addTo(map);
          
      },
      style: function (feature) {
        //if ( firstLayer === "existant" ) {
          let nbr_habit = feature.properties["hab_lamp"];
          if (nbr_habit>0 && nbr_habit< 20) {
            return defaultStyle;
          }
          if (nbr_habit>=20 && nbr_habit< 60) {
            return firstStyle;
          }
          if (nbr_habit>=60 && nbr_habit<100) {
            return secondStyle;
          }
          if (nbr_habit>=100 && nbr_habit<200) {
            return thirdStyle;
          }
          if (nbr_habit>=200 || nbr_habit ===0) {
            return fourthStyle;
          }
        //}

      }
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

