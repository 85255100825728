import React, { useState, useEffect } from 'react';
import './home.scss';
import { Button } from '@mui/material';
import sunnaLogo from "../../assets/images/sunna-white-logo.svg";
import { LightModeOutlined } from '@mui/icons-material';
import { StackedLineChart } from '@mui/icons-material';
import { CompareArrows } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AutoFixHigh } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
//import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
//import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
//import MapWrapper from "../../components/map-wrapper/MapWrapper";
import SimulationResultComponent from "../../components/simulation-result/SimulationResultComponent";
import { simulatorService } from "../../services/simulatorService";
import { useDispatch, useSelector } from 'react-redux';
import { updateSimulationResult } from '../../store/reducers/simulationResultReducer'

//Imports for the old map functions
import Map from "../../components/map/Map";
import { baseLayers, countyPositions } from "../../assets/constants/const";
import LoadDataTask from "../../services/LoadDataTask";
import {
    dataLoadInfos,
    FrontiereLayers,
    FrontiereSimulatedLayers

} from "./layers";

const Home = () => {

    /* Lines added to show map 
      These lines will not be used in our main project
    */
    const dispatch = useDispatch();
/*
    const data ={
        totalLampadaires: 0,
        totalFrontiere: 0,
        totalFrontiereSavanes: 0,
        totalFrontiereOuest: 0,
        totalFrontiereEst: 0,
        totalFrontiereSudEst: 0,
        totalVillageCiso: 0,
        totalVillageCisoSavanes: 0,
        totalVillageCisoAutresRegions: 0,
        totalZoneCiso: 0,
        totalZoneCisoSavanes: 0,
        totalZoneCisoAutresRegions: 0,
        totalInfraHorsCisoEducation: 0,
        totalInfraHorsCisoSante: 0,
        totalInfraHorsCisoEau: 0
    };
*/
    const [subdivisionLevel, setSubdivisionLevel] = useState("canton");
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState(0);
    const [mapData, setMapData] = useState({});
    const [selectedCounty] = useState(0);

    /* End **/
    const [kmDiametre, setKmDiametre] = useState("frontiereKM0Info");
    const [simulationData, setSimulationData] = useState({});
    //const [simulated, setSimulated] = useState(false);
    const [simulatedInfo, setSimulatedInfo] = useState(false);
    const layers = [
        FrontiereLayers,
        FrontiereSimulatedLayers
    ];

    const layerSelectionButtons = {
        kmDiametre,
        setKmDiametre
    };

    const displayedLayers = {
        [FrontiereLayers.slug]: kmDiametre,
        [FrontiereSimulatedLayers.slug]: simulatedInfo
    };


    const loadData = function () {

        let mounted = { val: true };
        const loadDataTask = new LoadDataTask();
        dataLoadInfos.forEach((info) => {

            loadDataTask.load(info, mounted, setMapData);

        });
        /*sharedDataLoadInfos.forEach((info) => {
          loadDataTask.load(info, mounted, setMapData);
        });*/
        return () => (mounted.val = false);
    };


    const calculateSimulationResult = function () {

        //SimulatorService();
    };



    useEffect(() => {

        loadData();
        // eslint-disable-next-line
    }, []);

    const formik = useFormik({
        initialValues: {
            lampDistribute: 50000,
            frontSavanes: 0,
            frontOuest: 0,
            frontEst: 0,
            frontSudEst: 0,
            rayonFrontalier: 0,
            savanesCizo12: 0,
            autresRegionsCizo12: 0,
            savanesCizoEP: 0,
            autresRegionsCizoEP: 0
        },
        onSubmit: async (values, actions) => {
            let parameters = { ...values };
            console.log(parameters);
            await setSimulationData(parameters);
            let mydata = simulatorService(parameters, formik.values.rayonFrontalier);
            //console.log(mydata);
            dispatch(updateSimulationResult(mydata));

        },
    })

    useEffect(() => {

        calculateSimulationResult();
        // eslint-disable-next-line
    }, [simulationData]);

    /* Slider value change */

    const [SliderValue, setSliderValue] = React.useState(0);

    const frontiereKMChangeHandler = (event, newValue) => {
        setSliderValue(newValue);
        formik.values.rayonFrontalier = newValue;
        let selectedFrontiereDiametre = 'frontiereKM' + newValue + 'Info';
        setKmDiametre(selectedFrontiereDiametre);
    };



    const handleExistantClick = ()=>{
        setSimulatedInfo(false);
        let selectedKM = 0;// SliderValue;
        let selectedFrontiereDiametre = 'frontiereKM' + selectedKM + 'Info';
        setKmDiametre(selectedFrontiereDiametre);
        
    };

    const handleSimulationClick = ()=>{
        /*let selectedKM = 5;// SliderValue;
        let selectedFrontiereDiametre = 'frontiereKM' + selectedKM + 'Info';
        setKmDiametre(selectedFrontiereDiametre);*/
        setSimulatedInfo(true);
    };

/*
    const handleDifferenceClick = ()=>{
        let selectedKM = 0;// SliderValue;
        let selectedFrontiereDiametre = 'frontiereKM' + selectedKM + 'Info';
        setKmDiametre(selectedFrontiereDiametre);
    };
*/


    /* Parameters hide and show */

    const [isActiveParameters, setActiveParameters] = useState(false);

    const handleToggleParameters = () => {
        setActiveParameters(!isActiveParameters);
    };

    /* Get screen dimensions */

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    };

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
            if (screenSize.dynamicWidth <= 1200) setActiveParameters(true);
        })
    }, [screenSize]);

    window.onload = function () {
        if (screenSize && screenSize.dynamicWidth <= 1200) {
            setActiveParameters(true);
        };
    }

    /* Check simulator results window status */

    const [resultWindow, setResultWindow] = useState('');
    const resultStatus = useSelector((state) => state.simulationResult.value.resultStatus);

    useEffect(() => {
        setResultWindow(resultStatus);
        //console.log(resultWindow);
        /* eslint-enable */
    }, [resultStatus]);

    return (
        <div className="home">
            <div className="home-inner">
                <div className="home-inner_header">
                    <div className="home-inner_header_left">
                        <img src={sunnaLogo} alt="logo" />
                    </div>
                    <div className="home-inner_header_right">
                        <h1>CIZO-SIM</h1>
                        <div className="divider"></div>
                        <p>
                            Simulateur d’éclairage frontalier et rural
                        </p>
                    </div>
                </div>
                <div className={"home-inner_body " + (isActiveParameters ? "closeParameters" : "openParameters") + (resultWindow === true ? " closeResults" : " openResults")}>
                    {/* Search parameters start here */}
                    <div className={"home-inner_body_parameters " + (isActiveParameters ? "close" : "open")}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="home-inner_body_parameters_content">
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_left">
                                        <h2>Paramètres</h2>
                                    </div>
                                    <div className="home-inner_body_parameters_content_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="lampDistribute"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lampDistribute}
                                                onChange={formik.handleChange}
                                            />
                                            <span>Lampadaires à distribuer</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item radio-group">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <p>Calculer par :</p>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                            >
                                                <div className="radio-group-wrapper">
                                                    <FormControlLabel value="female" control={<Radio />} label="Ratio" />
                                                    <Tooltip title="info" placement="top" arrow>
                                                        <InfoOutlinedIcon className="info" />
                                                    </Tooltip>
                                                </div>
                                                {/*
                                                <div className="radio-group-wrapper">
                                                    <FormControlLabel value="male" control={<Radio />} label="Pourcentage" />
                                                    <Tooltip title="info" placement="top" arrow>
                                                        <InfoOutlinedIcon className="info" />
                                                    </Tooltip>
                                                </div>
                                            */}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item title">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <h3>Frontières et ratio souhaité</h3>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Frontière-Savanes" />
                                            }
                                            label="Frontière Savanes"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="frontSavanes"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.frontSavanes}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Frontière-Ouest" />
                                            }
                                            label="Frontière Ouest"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="frontOuest"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.frontOuest}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Frontière-Est" />
                                            }
                                            label="Frontière Est"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="frontEst"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.frontEst}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Frontière-Sud-Est" />
                                            }
                                            label="Frontière Sud-Est"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="hhome-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="frontSudEst"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.frontSudEst}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="home-inner_body_parameters_content_item title">
                                    <div className="home-map-parameters_item_left">
                                        <h3>Définition du rayon frontalier</h3>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_full slider">
                                        <p>{SliderValue} km</p>
                                        <Slider
                                            name="rayonFrontalier"
                                            step={1}
                                            min={0}
                                            max={20}
                                            value={typeof SliderValue === 'number' ? SliderValue : 0}
                                            onChange={frontiereKMChangeHandler}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                        />
                                        <p>20 km</p>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="home-inner_body_parameters_content_item title">
                                    <div className="home-map-parameters_item_left">
                                        <h3>Villages CIZO</h3>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Savanes" />
                                            }
                                            label="Région des Savanes"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="savanesCizo12"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.savanesCizo12}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Autres-régions" />
                                            }
                                            label="Autres régions"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="autresRegionsCizo12"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.autresRegionsCizo12}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item title except">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <h3>Zone CIZO</h3>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Savanes" />
                                            }
                                            label="Région des Savanes"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="savanesCizoEP"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.savanesCizoEP}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Autres-régions" />
                                            }
                                            label="Autres régions"
                                        />
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                    <div className="home-map-parameters_item_right">
                                        <p>
                                            <input
                                                type="text"
                                                name="autresRegionsCizoEP"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.autresRegionsCizoEP}
                                                onChange={formik.handleChange}
                                            />
                                            <span>hab./lamp.</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="home-inner_body_parameters_content_item title">
                                    <div className="home-inner_body_parameters_content_item_left">
                                        <h3>Infrastructures non-éclairées hors CIZO</h3>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item_right">
                                        <Tooltip title="info" placement="top" arrow>
                                            <InfoOutlinedIcon className="info" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="home-inner_body_parameters_content_item">
                                    <div className="home-inner_body_parameters_content_item_full checkbox-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Éducation" />
                                            }
                                            label="Éducation"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Santé" />
                                            }
                                            label="Santé"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox defaultChecked name="Eau" />
                                            }
                                            label="Eau"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="home-inner_body_parameters_footer">
                                <Button type="submit" disabled={formik.isSubmitting}>
                                    <AutoFixHigh className="magic" />
                                    <span>LANCER SIMULATION</span>
                                    {
                                        formik.isSubmitting ?
                                            (<CircularProgress />) : ('')
                                    }
                                </Button>
                            </div>
                        </form>
                        <div className="home-inner_body_parameters_collapse">
                            <Tooltip title={isActiveParameters ? "Afficher" : "Cacher"} placement="top" arrow>
                                <button onClick={handleToggleParameters}><ArrowBackIosNewIcon /></button>
                            </Tooltip>
                        </div>
                    </div>
                    {/* Search parameters end here */}
                    {/* Map start here */}
                    <div className="home-inner_body_viewer">
                        <div className="home-inner_body_viewer_content">
                            <div className="map">
                                <div id="map">
                                <Map
                                        subdivisionLevel={subdivisionLevel}
                                        setSubdivisionLevel={setSubdivisionLevel}
                                        selectedRegion={selectedRegion}
                                        setSelectedRegion={setSelectedRegion}
                                        selectedPrefecture={selectedPrefecture}
                                        setSelectedPrefecture={setSelectedPrefecture}
                                        selectedCommune={selectedCommune}
                                        setSelectedCommune={setSelectedCommune}

                                        layers={layers}
                                        displayedLayers={displayedLayers}
                                        mapData={mapData}
                                        latLng={countyPositions[selectedCounty].latLng}
                                        zoom={countyPositions[selectedCounty].zoom}
                                        baseLayers={baseLayers}
                                        layerSelectionButtons={layerSelectionButtons}
                                />
                                </div>
                            </div>
                            <div className="legend">
                                <h3>Habitants par lampadaire</h3>
                                <ul>
                                    <li>
                                        <span className="legend-color">
                                        </span>
                                    </li>
                                    <li>200.00, 864.00</li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className="legend-color">
                                        </span>
                                    </li>
                                    <li>100.00, 200.00</li>
                                </ul>

                                <ul>
                                    <li>
                                        <span className="legend-color">
                                        </span>
                                    </li>
                                    <li>60.00, 100.00</li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className="legend-color">
                                        </span>
                                    </li>
                                    <li>20.00, 60.00</li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className="legend-color">
                                        </span>
                                    </li>
                                    <li>3.00, 20.00</li>
                                </ul>
                            </div>
                        </div>
                        <div className="home-inner_body_viewer_footer">
                            <Button onClick={ handleExistantClick }>
                                <LightModeOutlined />EXISTANT
                            </Button>
                            <Button onClick={ handleSimulationClick }>
                                <StackedLineChart />Simulation 
                            </Button>
                            <Button>
                                <CompareArrows />différence
                            </Button>
                        </div>
                    </div>
                    {/* Map end here */}
                    {/* Search results start here */}
                    < SimulationResultComponent simulationData={simulationData} screenSize={screenSize} />
                    {/* Search results end here */}
                </div>
                <div className="home-inner_footer">
                    <p>Développé par Mitsio Motu | Assistance téchnique : +228 70 50 58 61</p>
                </div>
            </div>
        </div>
    )
}

export default Home;
