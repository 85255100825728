import { connect } from 'react-redux';
import { setOpen, setMessage, setColor, setXposition, setYposition } from '../reducers/snackBarReducer';
import SnackBarComponent from '../../components/snackbar/SnackBarComponent';

const mapStateToProps = state => ({
    open: state.snackbar.open,
    message: state.snackbar.message,
    color: state.snackbar.color,
    xPosition: state.snackbar.xPosition,
    yPosition: state.snackbar.yPosition,
});

const mapDispatchToProps = dispatch => ({
    setOpen: (open) => {
        dispatch(setOpen({ open: open }));
    },
    setMessage: (message) => {
        dispatch(setMessage({ message: message }));
    },
    setColor: (color) => {
        dispatch(setColor({ color: color }));
    },
    setXposition: (xPosition) => {
        dispatch(setXposition({ xPosition: xPosition }));
    },
    setYposition: (yPosition) => {
        dispatch(setYposition({ yPosition: yPosition }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarComponent);