import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './SnackBarComponent.scss';
import Slide from '@mui/material/Slide';

// Color could be error, warning, info, success
const SnackBarComponent = ({ open, message, setOpen, color, xPosition, yPosition }) => {

    console.log(xPosition, yPosition);
    console.log(color);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: yPosition,
                horizontal: xPosition,
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleCloseSnackBar}
            message={message}
            TransitionComponent={Slide}
            className={color}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

export default SnackBarComponent;