import { createSlice } from '@reduxjs/toolkit';
//import  SimulatedData  from '../../data/togo_eclairage_base_calcul.json';
import  SimulatedCantonData  from '../../data/togo_eclairage_calculated_cantons.json';

const initialStateValue = SimulatedCantonData;

/* eslint-disable */
export const layerSlice = createSlice({
    name: 'layers',
    initialState: {value: initialStateValue},
    reducers: {
        addLayer: (state, action) => {
            state.value = action.payload;
        },
        removeLayer: (state, action) => {
            state.data = state.data.filter( elt => elt.id !== action.payload.id)
        }
    }
});
/* eslint-enable */


export const { addLayer, removeLayer } = layerSlice.actions;
export default layerSlice.reducer;