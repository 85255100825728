//import { useSelector } from 'react-redux';

import dataKm from '../data_calcul/togo_export_site_concat.json'
//import dataCalculatedKm from '../data/togo_eclairage_base_calcul.json'
//import dataCalculatedCantonsKm from '../data/togo_eclairage_calculated_cantons.json'


//
//Function to calculate all the total of needed lampadaires on the frontieres
//
export const calculTotalFrontière = (data,frontiere,ratio)=>{

	var nbrLampadairesToBuild= 0;
	var nbrNewLampadaires = 0;
	if(parseInt(ratio)>0){
		if(data.properties['frontiere'] === frontiere){

			nbrLampadairesToBuild= Math.round(parseInt(data.properties['popCouv']) / parseInt(ratio));
			nbrNewLampadaires = nbrLampadairesToBuild - parseInt(data.properties['lampCouv']);
		}
	}


	return nbrNewLampadaires;
}



//
//Function to calculate all the total of needed lampadaires in Villages CISO
//
export const calculTotalVillageCiso = (data,region,ratio)=>{

	var nbrLampadairesToBuild= 0;
	var nbrNewLampadaires = 0;
	if(parseInt(ratio)>0){
		if(data.properties['region_nom'] === region){

			nbrLampadairesToBuild= Math.round(parseInt(data.properties['popCouv']) / parseInt(ratio));
			nbrNewLampadaires = nbrLampadairesToBuild - parseInt(data.properties['lampCouv']);
		}else{
			nbrLampadairesToBuild= Math.round(parseInt(data.properties['popCouv']) / parseInt(ratio));
			nbrNewLampadaires = nbrLampadairesToBuild - parseInt(data.properties['lampCouv']);
		}
	}
	


	return nbrNewLampadaires;
}



//
//Function to calculate all the total of needed lampadaires in Zone CISO
//
export const calculTotalZoneCiso = (data,region,ratio)=>{

	var nbrLampadairesToBuild= 0;
	var nbrNewLampadaires = 0;
	if(parseInt(ratio)>0){
		if(data.properties['region_nom'] === region){

			nbrLampadairesToBuild= Math.round(parseInt(data.properties['popCouv']) / parseInt(ratio));
			nbrNewLampadaires = nbrLampadairesToBuild - parseInt(data.properties['lampCouv']);
		}else{
			nbrLampadairesToBuild= Math.round(parseInt(data.properties['popCouv']) / parseInt(ratio));
			nbrNewLampadaires = nbrLampadairesToBuild - parseInt(data.properties['lampCouv']);
		}
	}
	


	return nbrNewLampadaires;
}


//
//Function to calculate all the total of needed lampadaires in areas except CIZO zones
//
export const calculTotalInfra = (data,typeInfra)=>{

	var nbrNewLampadaires = 0;
	if(typeInfra  === 'ecole'){
		nbrNewLampadaires = parseInt(data.properties['ecoleCouv']) * 2;
	}

	if(typeInfra  === 'sante'){
		nbrNewLampadaires = parseInt(data.properties['santeCouv']) * 2;
	}

	if(typeInfra  === 'eau'){
		nbrNewLampadaires = parseInt(data.properties['eauCouv']) * 1;
	}


	return nbrNewLampadaires;
}



// Main function
// Function to calculate all the total of needed lampadaires considering the selected frontiere Rayons
//
export const simulatorService = (sentSimulationData,frontiereKmSelected)=>{

	const data ={
        totalLampadaires: 0,
        totalFrontiere: 0,
        totalFrontiereSavanes: 0,
        totalFrontiereOuest: 0,
        totalFrontiereEst: 0,
        totalFrontiereSudEst: 0,
        totalVillageCiso: 0,
        totalVillageCisoSavanes: 0,
        totalVillageCisoAutresRegions: 0,
        totalZoneCiso: 0,
        totalZoneCisoSavanes: 0,
        totalZoneCisoAutresRegions: 0,
        totalInfraHorsCisoEducation: 0,
        totalInfraHorsCisoSante: 0,
        totalInfraHorsCisoEau: 0
    };
    const simulationRequestData = sentSimulationData;
    

    var totalLampadaires= 0;
	var totalFrontiere= 0;
	var totalFrontiereSavanes= 0;
	var totalFrontiereOuest= 0;
	var totalFrontiereEst= 0;
	var totalFrontiereSudEst= 0;
	var totalVillageCiso= 0;
	var totalVillageCisoSavanes= 0;
	var totalVillageCisoAutresRegions= 0;
	var totalZoneCiso= 0;
	var totalZoneCisoSavanes= 0;
	var totalZoneCisoAutresRegions= 0;
	var totalInfraHorsCisoEducation= 0;
	var totalInfraHorsCisoSante= 0;
	var totalInfraHorsCisoEau= 0;

	
	dataKm.features.forEach(function(feature){
		if(feature.properties['km']=== frontiereKmSelected*1000 ){
			//console.log(feature.properties['canton_id'])
			if(feature.properties['type']==='Frontière'){
				totalFrontiereSavanes = totalFrontiereSavanes + calculTotalFrontière (feature,'Savanes',simulationRequestData.frontSavanes);
				totalFrontiereOuest = totalFrontiereOuest + calculTotalFrontière (feature,'Ouest',simulationRequestData.frontOuest);
				totalFrontiereEst = totalFrontiereEst + calculTotalFrontière (feature,'Est',simulationRequestData.frontEst);
				totalFrontiereSudEst = totalFrontiereSudEst + calculTotalFrontière (feature,'Sud-Est',simulationRequestData.frontSudEst);

			}
			if(feature.properties['type']==='Villages CIZO'){
				totalVillageCisoSavanes = totalVillageCisoSavanes + calculTotalVillageCiso (feature,'Savanes',simulationRequestData.savanesCizo12);
				totalVillageCisoAutresRegions = totalVillageCisoAutresRegions + calculTotalVillageCiso (feature,'Maritime',simulationRequestData.autresRegionsCizo12);
				totalVillageCisoAutresRegions = totalVillageCisoAutresRegions + calculTotalVillageCiso (feature,'Plateaux',simulationRequestData.autresRegionsCizo12);
				totalVillageCisoAutresRegions = totalVillageCisoAutresRegions + calculTotalVillageCiso (feature,'Centrale',simulationRequestData.autresRegionsCizo12);
				totalVillageCisoAutresRegions = totalVillageCisoAutresRegions + calculTotalVillageCiso (feature,'Kara',simulationRequestData.autresRegionsCizo12);

			}
			if(feature.properties['type']==='Zone CIZO'){
				totalZoneCisoSavanes = totalZoneCisoSavanes + calculTotalVillageCiso (feature,'Savanes',simulationRequestData.savanesCizoEP);
				totalZoneCisoAutresRegions = totalZoneCisoAutresRegions + calculTotalZoneCiso (feature,'Maritime',simulationRequestData.autresRegionsCizoEP);
				totalZoneCisoAutresRegions = totalZoneCisoAutresRegions + calculTotalZoneCiso (feature,'Plateaux',simulationRequestData.autresRegionsCizoEP);
				totalZoneCisoAutresRegions = totalZoneCisoAutresRegions + calculTotalZoneCiso (feature,'Centrale',simulationRequestData.autresRegionsCizoEP);
				totalZoneCisoAutresRegions = totalZoneCisoAutresRegions + calculTotalZoneCiso (feature,'Kara',simulationRequestData.autresRegionsCizoEP);

			}
			if(feature.properties['type']==='Reste'){
				totalInfraHorsCisoEducation = totalInfraHorsCisoEducation + calculTotalInfra (feature,'ecole');
				totalInfraHorsCisoSante = totalInfraHorsCisoSante + calculTotalInfra (feature,'sante');
				totalInfraHorsCisoEau = totalInfraHorsCisoEau + calculTotalInfra (feature,'eau');
			}

		}
	});
	
	data.totalFrontiereSavanes = totalFrontiereSavanes;
	data.totalFrontiereOuest = totalFrontiereOuest;
	data.totalFrontiereEst = totalFrontiereEst;
	data.totalFrontiereSudEst = totalFrontiereSudEst;
	totalFrontiere = totalFrontiereSavanes + totalFrontiereOuest + totalFrontiereEst + totalFrontiereSudEst;
	data.totalFrontiere = totalFrontiere;

	data.totalVillageCisoSavanes = totalVillageCisoSavanes;
	data.totalVillageCisoAutresRegions = totalVillageCisoAutresRegions;
	totalVillageCiso = totalVillageCisoSavanes + totalVillageCisoAutresRegions;
	data.totalVillageCiso = totalVillageCiso;

	data.totalZoneCisoSavanes = totalZoneCisoSavanes;
	data.totalZoneCisoAutresRegions = totalZoneCisoAutresRegions;
	totalZoneCiso = totalZoneCisoSavanes + totalZoneCisoAutresRegions;
	data.totalZoneCiso = totalZoneCiso;

	data.totalInfraHorsCisoEducation = totalInfraHorsCisoEducation;
	data.totalInfraHorsCisoSante = totalInfraHorsCisoSante;
	data.totalInfraHorsCisoEau = totalInfraHorsCisoEau;

	totalLampadaires= totalFrontiere + totalVillageCiso + totalZoneCiso + totalInfraHorsCisoEducation + totalInfraHorsCisoSante + totalInfraHorsCisoEau;

	data.totalLampadaires = totalLampadaires;

	return data;

}

