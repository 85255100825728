import { configureStore } from '@reduxjs/toolkit'
import snackBarReducer from './reducers/snackBarReducer'
import userReducer from './reducers/userReducer'
import layerReducer from './reducers/layerReducer'
import simulationResultReducer from './reducers/simulationResultReducer'

export default configureStore({
    reducer: {
        snackbar: snackBarReducer,
        user: userReducer,
        layersSimulated: layerReducer,
        simulationResult: simulationResultReducer
    },
})