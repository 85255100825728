import React from 'react';
import './login.scss';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { CircularProgress } from '@mui/material';
import sunnaLogo from "../../assets/images/sunna-design-logo.svg";
import gouvernement from "../../assets/images/Gouvernement.png";
//import MMLogo from "../../assets/images/mm-logo.svg";
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const navigate = useNavigate();

    /*
        const handleChange = (prop) => (event) => {
            setValues({ ...values, [prop]: event.target.value });
        };
    */
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            mail: '',
            password: '',
        },
        validationSchema: Yup.object({
            mail: Yup.string()
                .required("Ce champ est requis"),
            password: Yup.string()
                .required('Ce champ est requis'),
        }),
        onSubmit: (values, actions) => {
            /* let user = { ...values };
            console.log(user); */
            navigate('/accueil');
        },
    });

    return (
        <div className='login'>
            <div className="login-left">
                <div className="login-left-logo">
                    <div className="login-left-logo-item">
                        <img src={gouvernement} alt="logo" />
                    </div>
                    <div className="login-left-logo-item">
                        <img src={sunnaLogo} alt="logo" />
                    </div>
                </div>
                <div className="login-left-cizo-text">
                    <h1>CIZO-SIM</h1>
                </div>
                <div className="login-left-form">
                    <h2>Accès client</h2>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <TextField
                                name="mail"
                                label="Courriel"
                                id="outlined-size-small"
                                size="small"
                                className={formik.errors.mail && formik.touched.mail ? ' is-invalid' : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mail}
                            />
                            {formik.touched.mail && formik.errors.mail ? (
                                <p className="error">{formik.errors.mail}</p>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <FormControl
                                variant="outlined"
                                className={"mdp" + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}>
                                <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                                <OutlinedInput
                                    name="password"
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    size="small"
                                    onChange={formik.handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            {formik.touched.password && formik.errors.password ? (
                                <p className="error">{formik.errors.password}</p>
                            ) : null}
                        </div>
                        <div className="form-group submit">
                            <Button type="submit" disabled={formik.isSubmitting} variant="contained">
                                <span>Se connecter</span>
                                {
                                    formik.isSubmitting ?
                                        (<CircularProgress />) : ('')
                                }
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="login-left-footer">
                    <p>
                        Assistance téchnique : +228 70 50 58 61
                    </p>
                </div>
            </div>
            <div className="login-right">

            </div>
        </div>
    )
}

export default Login;
